import React from "react"
import Img from "gatsby-image"
import { graphql } from "gatsby"
import styled from "styled-components"

import SEO from "../components/seo.js"
import Title from "../components/title"
import Layout from "../components/layout"
import { WPContent } from "../components/globalstyles.js"

const PageTemplate = props => {
  console.log(props.data.currentPage)

  const fluid =
    props.data &&
    props.data.currentPage &&
    props.data.currentPage.featured_media &&
    props.data.currentPage.featured_media.localFile.childImageSharp.fluid

  return (
    <Layout>
      <SEO title={props.data.currentPage.title} />
      <Title>{props.data.currentPage.title}</Title>
      {fluid && (
        <StyledImg>
          <Img fluid={fluid} />
        </StyledImg>
      )}
      <WPContent
        dangerouslySetInnerHTML={{
          __html: props.data.currentPage.content,
        }}
      />
    </Layout>
  )
}

const StyledImg = styled.div`
  width: 200px;
  height: 200px;
  float: right;
  margin-left: 20px;
  border-radius: 50%;
  overflow: hidden;
  border: 5px solid #fac8c2;
`

export default PageTemplate

export const PageTemplateQuery = graphql`
  query($id: String!, $parent: Int!, $wpId: Int!) {
    currentPage: wordpressPage(id: { eq: $id }) {
      title
      content
      wordpress_parent
      featured_media {
        localFile {
          childImageSharp {
            fluid {
              base64
              aspectRatio
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
              originalImg
              originalName
              presentationWidth
              presentationHeight
            }
          }
        }
      }
    }
    parentChildren: allWordpressPage(
      filter: { wordpress_parent: { eq: $parent } }
    ) {
      edges {
        node {
          id
          title
          link
        }
      }
    }

    children: allWordpressPage(filter: { wordpress_parent: { eq: $wpId } }) {
      edges {
        node {
          id
          title
          link
        }
      }
    }

    parent: wordpressPage(wordpress_id: { eq: $parent }) {
      title
      link
    }
  }
`
